import { disableVesselTimes, enableVesselTimes } from '@/api/aqvvesseltime';

const disableVesselTime = {
	id: 'disableVesselTime',
	selectionType: 'multiple',
	label: 'aqvvesseltime.actions.disableVesselTime',
	functionality: 'UPDATE_AQVVESSELTIME',
	checkAvailability: function (instance) {
		return instance && instance.disabled === 0;
	},
	runAction: async function (action, model, registries) {
		var title = this.$puiI18n.t('aqvvesseltime.actions.disableVesselTime');

		var body = [];
		for (let i = 0; i < registries.length; i++) {
			body.push({
				aqvvesseltimeid: registries[i].aqvvesseltimeid
			});
		}

		const data = await disableVesselTimes(body);
		if (data.error) {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifyError');
			this.$puiNotify.error(message, title);
		} else {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifySuccess');
			this.$puiNotify.success(message, title);
		}
	}
};

const enableVesselTime = {
	id: 'enableVesselTime',
	selectionType: 'multiple',
	label: 'aqvvesseltime.actions.enableVesselTime',
	functionality: 'UPDATE_AQVVESSELTIME',
	checkAvailability: function (instance) {
		return instance && instance.disabled === 1;
	},
	runAction: async function (action, model, registries) {
		var title = this.$puiI18n.t('aqvvesseltime.actions.enableVesselTime');

		var body = [];
		for (let i = 0; i < registries.length; i++) {
			body.push({
				aqvvesseltimeid: registries[i].aqvvesseltimeid
			});
		}

		const data = await enableVesselTimes(body);
		if (data.error) {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifyError');
			this.$puiNotify.error(message, title);
		} else {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifySuccess');
			this.$puiNotify.success(message, title);
		}
	}
};

export default {
	actions: [disableVesselTime, enableVesselTime]
};
